function ImgCloud2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 100" xmlSpace="preserve">
      <defs>
        <clipPath id="clipPath25242" clipPathUnits="userSpaceOnUse">
          <path
            fill="#fff"
            fillOpacity="1"
            strokeWidth="0"
            d="M83.783 234.712c13.667-.594 21.986 8.319 32.682 6.536 10.695-1.782 95.073 5.348 98.044-8.319 2.971-13.666-26.74-10.101-26.74-10.101s1.783-11.884-10.1-16.044c-11.885-4.16-21.987-2.377-21.987-2.377s-2.376-29.71-16.043-38.623c-13.667-8.913-32.682-5.942-32.682-5.942s10.304-41.03-33.588-44.028c-50.742-3.466-60.296 47.593-60.296 47.593s-18.42-4.16-26.146 3.565c-7.724 7.725-7.724 20.203-7.724 20.203s-21.986-3.565-29.116 5.348c-7.13 8.913-10.696 20.798-10.696 20.798s-24.957-8.914-32.087-3.566c-7.13 5.348-6.537 10.102-6.537 10.102s-16.638-18.42-27.927-6.536c-11.29 11.884 9.507 27.333 9.507 27.333s56.45 8.913 80.218 1.783c23.768-7.13 57.044-8.32 70.116-1.783 13.073 6.536 51.102-5.942 51.102-5.942z"
            display="inline"
            opacity="1"
            paintOrder="stroke fill markers"
          ></path>
        </clipPath>
      </defs>
      <g>
        <g
          fillOpacity="1"
          strokeWidth="0"
          display="inline"
          paintOrder="stroke fill markers"
          transform="matrix(.57931 0 0 .77186 75.611 -89.262)"
        >
          <path
            fill="#fff"
            d="M83.783 234.712c13.667-.594 21.986 8.319 32.682 6.536 10.695-1.782 95.073 5.348 98.044-8.319 2.971-13.666-26.74-10.101-26.74-10.101s1.783-11.884-10.1-16.044c-11.885-4.16-21.987-2.377-21.987-2.377s-2.376-29.71-16.043-38.623c-13.667-8.913-32.682-5.942-32.682-5.942s10.304-41.03-33.588-44.028c-50.742-3.466-60.296 47.593-60.296 47.593s-18.42-4.16-26.146 3.565c-7.724 7.725-7.724 20.203-7.724 20.203s-21.986-3.565-29.116 5.348c-7.13 8.913-10.696 20.798-10.696 20.798s-24.957-8.914-32.087-3.566c-7.13 5.348-6.537 10.102-6.537 10.102s-16.637-18.42-27.927-6.536c-11.29 11.884 9.507 27.333 9.507 27.333s56.45 8.913 80.218 1.783c23.768-7.13 57.044-8.32 70.116-1.783 13.073 6.536 51.102-5.942 51.102-5.942z"
            display="inline"
            opacity="1"
          ></path>
          <g clipPath="url(#clipPath25242)">
            <path
              fill="#d1d5f8"
              d="M16.837 119.664s46.302-18.04 64.943 6.013c18.641 24.053 0 56.525 0 56.525s28.864-12.027 40.29 5.412c11.424 17.438 3.006 34.877 3.006 34.877s18.04-5.412 25.857 4.81c7.817 10.223 10.824 28.262 10.824 28.262h-307.278l17.439-78.172z"
              opacity="1"
            ></path>
            <path
              fill="#b7bce8"
              d="M-71.558 175.587s30.334-.495 42.886 6.677C.793 199.101-7.817 212.87-7.817 212.87s15.634-11.425 28.262 2.406c12.628 13.83 24.053 36.08 24.053 36.08l-167.168 6.614-40.89-40.89z"
              opacity="1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ImgCloud2;
