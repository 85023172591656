import React from 'react';
import { maps } from 'data/maps';
import { RouteDanger } from 'types';
import MapDangers from './MapDangers';

type Props = {
  location: string;
  availableLocations: string[];
  handleLocationSelect: (destination: string) => void;
};
const Map02: React.FC<Props> = ({ location, availableLocations, handleLocationSelect }) => {
  const dangerIcons: RouteDanger[] = [];
  maps[1].routes.forEach((route) => {
    route.sections.forEach((section) => {
      section.dangers.forEach((danger) => {
        dangerIcons.push(danger);
      });
    });
  });
  const getDotProps = (dotLocation: string) => {
    const props: any = {};
    if (availableLocations.includes(dotLocation)) {
      props.className = 'fill-green-500 hover:fill-gray-200 transition-all duration-150';
      props.role = 'button';
      props['aria-label'] = dotLocation;
      props.onClick = () => {
        handleLocationSelect(dotLocation);
      };
    } else if (location === dotLocation) {
      props.className = 'fill-gray-300';
    } else {
      props.className = 'fill-orange-800';
    }
    return props;
  };
  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 100 100"
        data-testid="map-02"
        data-check={location}
      >
        <defs>
          <linearGradient id="linearGradient10425">
            <stop offset="0" stopColor="#007400" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#009100" stopOpacity="1"></stop>
          </linearGradient>
          <linearGradient id="linearGradient10199">
            <stop offset="0" stopColor="#008d00" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#007600" stopOpacity="1"></stop>
          </linearGradient>
          <linearGradient id="linearGradient6774">
            <stop offset="0" stopColor="#4ac9fd" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#4a90fd" stopOpacity="1"></stop>
          </linearGradient>
          <linearGradient id="linearGradient4242">
            <stop offset="0.555" stopColor="#fca" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#e9ab86" stopOpacity="1"></stop>
          </linearGradient>
          <radialGradient
            id="radialGradient4244"
            cx="50"
            cy="50"
            r="49.5"
            fx="50"
            fy="50"
            gradientTransform="matrix(1.76137 -.01434 .01534 1.88418 -38.836 -43.492)"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient4242"
          ></radialGradient>
          <linearGradient
            id="linearGradient6776"
            x1="75.556"
            x2="74.013"
            y1="77.781"
            y2="120.389"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient6774"
          ></linearGradient>
          <linearGradient
            id="linearGradient10201"
            x1="31.069"
            x2="-9.412"
            y1="86.018"
            y2="105.473"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient10199"
          ></linearGradient>
          <linearGradient
            id="linearGradient10427"
            x1="-14.302"
            x2="31.287"
            y1="21.741"
            y2="5.751"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient10425"
          ></linearGradient>
          <linearGradient
            id="linearGradient15741"
            x1="75.515"
            x2="92.363"
            y1="-23.487"
            y2="-50.41"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient10199"
          ></linearGradient>
          <clipPath id="clipPath1079" clipPathUnits="userSpaceOnUse">
            <path
              fill="#ffe6d5"
              strokeWidth="0"
              d="M-20.386-28.398l11.748-1.404s-.104 4.866 23.633 1.208c12.471-1.921 50.283-4.59 63.438-1.377 13.156 3.213 48.913-3.435 52.025 1.44 3.112 4.873 4.709 13.454 4.709 13.454s2.958 75.337 0 84.277c-1.683 5.083-3.658 19.164-.535 25.739 2.903 6.113 1.566 8.391 1.472 13.555-.095 5.163-4.784 8.47-2.504 17.168.95 3.624-21.642 1.604-21.642 1.604C82.51 116.32-26.825 133.6-20.93 121.796c0 0-3.09-37.157-.51-39.865 5.275-5.535-.934-48.995.688-52.883 1.277-3.06-2.112-40.207-.01-44.12 2.244-4.175.375-13.326.375-13.326z"
              display="inline"
              paintOrder="stroke fill markers"
            ></path>
          </clipPath>
        </defs>
        <g strokeWidth="1.96" transform="matrix(.5102 0 0 .5102 24.137 24.247)">
          <g
            fillOpacity="1"
            strokeOpacity="1"
            strokeWidth="1.618"
            clipPath="url(#clipPath1079)"
            transform="matrix(1.21109 0 0 1.21206 -18.216 -8.33)"
          >
            <path
              fill="url(#radialGradient4244)"
              stroke="none"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeWidth="0"
              d="M-48 -48H148V148H-48z"
              paintOrder="stroke fill markers"
            ></path>
            <g strokeDashoffset="0" strokeLinejoin="round" strokeWidth="1.618">
              <path
                fill="#0d630d"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="square"
                strokeWidth="0"
                d="M6.429-8.12c-6.287.179-9.52 4.67-16.885 3.951-3.683-.359-6.888.437-8.213 2.39-1.325 1.954-.77 5.066.668 6.592 2.874 3.054 12.754 8.263 4.49 10.778-4.13 1.258-12.187 8.797-12.187 8.797s-.656 2.61.871 3.599c3.054 1.976 12.575 3.233 9.161 7.724-1.706 2.245-3.01 2.973-3.19 4.59-.179 1.617.399 4.179 2.652 4.392 3.427.324 6.958-.206 9.732-2.727v.007l6.936-1.817s2.76-.588 4.742-2.404c1.982-1.817 5.166-4.2 6.652-4.2 1.486 0 3.798-1.157 5.45-4.294 1.65-3.138 1.486-5.946 3.302-5.946s2.972 2.477 5.614-.495c1.321-1.486 4.211-3.468 6.152-5.222 1.94-1.755 2.128-2.933 2.13-4.663-4.954-1.156-13.565-.519-11.419-2.996 2.147-2.477 8.355-3.5 8.059-5.73-.026-1.034.715-1.827-1.434-1.846-2.597-.022-9.7.374-12.164-1.233-2.464-1.608-1.395-6.992-5.373-5.888-2.346.651-3.032-3.437-5.746-3.36z"
                paintOrder="normal"
              ></path>
              <path
                fill="url(#linearGradient10427)"
                stroke="#0c550a"
                strokeDasharray="none"
                strokeLinecap="square"
                strokeWidth="0.485"
                d="M7.121-9.8C.834-9.622-2.399-5.13-9.764-5.85-17.13-6.567-20.184.08-17.31 3.133c2.874 3.054 12.754 8.263 4.491 10.778-8.263 2.515-14.37 10.42-11.317 12.396 3.054 1.976 12.575 3.233 9.162 7.724-3.413 4.49-4.312 8.443-.54 8.982 3.408.486 6.96-.207 9.733-2.727v.007l6.936-1.817s2.761-.588 4.743-2.404c1.981-1.817 5.165-4.2 6.651-4.2 1.486 0 3.798-1.157 5.45-4.294 1.65-3.138 1.486-5.946 3.302-5.946 1.817 0 2.972 2.477 5.614-.495 2.643-2.972 11.56-7.926 6.606-9.082-4.954-1.156-11.89-1.322-9.742-3.799 2.146-2.477 9.411-3.963 7.595-5.614-.713-.648-8.206.02-13.135-3.195-2.464-1.608-1.395-6.993-5.372-5.888-2.346.651-3.033-3.437-5.747-3.36z"
                paintOrder="normal"
                transform="translate(-.692 -.476)"
              ></path>
              <path
                fill="none"
                stroke="#20a120"
                strokeDasharray="0.808866, 6.47093"
                strokeLinecap="round"
                strokeWidth="0.809"
                d="M-.187 1.711S10.245 5.245 14.116 7.77c3.87 2.524 11.61 2.02 11.442 5.553-.168 3.533-2.356 4.88-4.712 4.88-2.355 0-6.394.168-7.908 4.375-1.515 4.206-7.404 11.442-10.601 11.442-3.197 0-8.919-2.692-8.919-2.692"
                display="none"
                paintOrder="normal"
              ></path>
              <path
                fill="none"
                stroke="#20a120"
                strokeDasharray="0.808864, 6.47093"
                strokeLinecap="round"
                strokeWidth="0.809"
                d="M-.02 7.937s5.722 2.356 8.751 2.524c3.029.169 13.462 1.01 10.264 3.366-3.197 2.355-10.096 4.375-10.264 7.067C8.563 23.586-1.365 30.99-1.365 30.99l-5.048-3.702"
                display="none"
                paintOrder="normal"
              ></path>
              <path
                fill="none"
                stroke="#20a120"
                strokeDasharray="0.808864, 6.47093"
                strokeLinecap="round"
                strokeWidth="0.809"
                d="M-3.384 12.817s11.105-1.01 10.432 2.02C6.375 17.864 2 23.586-1.029 25.268c-3.029 1.683-6.73-1.178-6.73-1.178"
                display="none"
                paintOrder="normal"
              ></path>
              <path
                fill="none"
                stroke="#20a120"
                strokeDasharray="0.808866, 3.23546"
                strokeLinecap="round"
                strokeWidth="0.809"
                d="M5.226-4.055s-12.312-3.599-16.1 1.894c-3.788 5.493 5.872 17.805-.947 20.836-6.82 3.03-3.789 8.334-3.789 8.334"
                display="none"
              ></path>
            </g>
            <g strokeDashoffset="0" strokeLinejoin="round" strokeWidth="1.618" display="inline">
              <path
                fill="#0d630d"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="square"
                strokeWidth="0"
                d="M54.138 68.149c-1.072-.016-2.374.14-3.823.493-4.637 1.128-3.008 3.133-5.014 3.509-2.005.376-3.257.376-3.383 2.13-.125 1.755-3.634 3.453-7.645 2.325-4.01-1.128-7.143.057-10.15.934-3.008.877-6.564 5.261-10.152 2.883-4.19-2.778-9.65 0-12.784-1.003-2.209-.707-3.838.447-4.618 1.194-5.593 2.015-8.235 8.502-13.52 7.495-6.038-1.15-5.75 8.05-5.75 8.05-.288 1.724-9.487 11.786-7.475 15.523 2.013 3.738 8.625-2.3 10.062 3.738 1.437 6.037-3.738 16.673 2.3 16.386 6.036-.288 11.787 12.362 18.399 6.9 6.612-5.463 6.611-19.549 12.36-18.111 5.75 1.437 8.626 11.786 10.925 5.174 2.3-6.612-3.737-11.5-1.15-13.799 2.588-2.3 9.226-2.928 9.47-7.243.177-3.114-.781-2.372-1.752-3.75.556-1.275 1.8-4.426 1.204-6.017-.752-2.005-1.254-.376.752-2.507 2.005-2.13.751-3.008 3.258-3.384 2.506-.376 6.767.377 8.397-3.132 1.629-3.51 4.01-5.64 8.773-6.267 4.762-.627 3.497-4.868 3.47-7.279-.016-1.465.202-4.207-2.154-4.242z"
                display="inline"
                paintOrder="normal"
              ></path>
              <path
                fill="url(#linearGradient10201)"
                stroke="#0c550a"
                strokeDasharray="none"
                strokeLinecap="square"
                strokeWidth="0.485"
                d="M54.138 66.58c-1.072-.016-2.374.141-3.823.494-4.637 1.128-3.008 3.132-5.014 3.508-2.005.376-3.257.377-3.383 2.131-.125 1.755-3.634 3.453-7.645 2.325-4.01-1.128-7.143.057-10.15.934-3.008.877-6.564 5.26-10.152 2.882-4.19-2.777-9.65 0-12.784-1.002-2.209-.707-3.838.447-4.618 1.194-5.593 2.015-8.235 8.502-13.52 7.495-6.038-1.15-5.75 8.05-5.75 8.05-.288 1.724-9.487 11.786-7.475 15.523 2.013 3.737 8.625-2.3 10.062 3.738 1.437 6.037-3.738 16.673 2.3 16.386 6.036-.288 11.787 12.361 18.399 6.9 6.612-5.463 6.611-19.549 12.36-18.112 5.75 1.438 8.626 11.787 10.925 5.175 2.3-6.612-3.737-11.5-1.15-13.8 2.588-2.299 8.912-3.162 9.2-7.474.06-.91-.511-2.14-1.482-3.519.556-1.274 1.8-4.425 1.204-6.016-.752-2.005-1.254-.376.752-2.507 2.005-2.13.751-3.008 3.258-3.384 2.506-.376 6.767.376 8.397-3.133 1.629-3.509 4.01-5.64 8.773-6.266 4.762-.627 3.007-7.018 4.135-9.148.776-1.465-.463-2.338-2.82-2.373z"
                display="inline"
                paintOrder="normal"
              ></path>
              <path
                fill="none"
                stroke="#20a120"
                strokeDasharray="0.808864, 3.23545"
                strokeLinecap="round"
                strokeWidth="0.809"
                d="M-5.909 85.51s11.275-6.058 15.313-5.217c4.039.841 2.946 5.412 6.48 3.561 3.534-1.85 11.207-9.544 15.312-7.404 7.734 4.033 16.99-5.046 19.682-7.065 2.692-2.02 5.305 3.504.93 6.028-4.375 2.524-12.284 4.375-12.452 7.068-.168 2.692-4.88 3.197-6.899 4.206-2.02 1.01-4.543 4.207-4.543 6.226 0 2.02 2.019 8.75 2.019 8.75"
                display="inline"
                paintOrder="normal"
              ></path>
              <path
                fill="none"
                stroke="#20a120"
                strokeDasharray="0.808866, 3.23546"
                strokeLinecap="round"
                strokeWidth="0.809"
                d="M-8.25 87.848s-3.276 2.621-7.208 3.932c-3.93 1.31-11.138 11.794-8.736 13.54 2.403 1.748 8.081 5.024 8.081 7.863 0 2.84-2.62 10.702.655 13.105 3.277 2.402 8.737 7.644 11.794 6.334 3.058-1.31 6.99-8.081 8.737-12.013 1.747-3.931 7.207-7.426 10.701-5.242 3.495 2.185 4.587-.873 4.369-3.712-.219-2.84 1.965-4.805 4.368-5.897 2.402-1.092 3.931-2.84 3.931-2.84"
              ></path>
            </g>
            <g
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="square"
              strokeLinejoin="round"
              strokeMiterlimit="4"
              strokeWidth="1.618"
              paintOrder="normal"
            >
              <path
                style={{ fontVariationSettings: 'normal' }}
                fill="#0d630d"
                stroke="none"
                strokeWidth="0"
                d="M31.769-44.236c3.718 5.07 7.098 11.83 6.084 15.549-.507 1.859-3.426 10.406-3.426 10.406s-.123 4.466 2.074 4.466c4.394 0 7.098-9.802 11.154-6.76s6.084 2.028 12.844 1.352c6.76-.676 7.774 5.408 12.168 4.394 4.394-1.014 17.913-2.408 17.913-4.774s-1.35-10.437 3.043-10.098c4.394.338 16.087.945 15.239 4.94-.79 3.718.648 7.228 6.056 6.214 5.408-1.014 16.562-5.408 20.618 0 4.056 5.408 7.436 7.774 9.126 7.098 1.69-.676 7.774-19.943 7.774-19.943l-10.478-27.716-106.81 8.112z"
                opacity="1"
                stopColor="#000"
                stopOpacity="1"
              ></path>
              <path
                style={{
                  fontVariationSettings: 'normal',
                }}
                fill="url(#linearGradient15741)"
                stroke="#0c550a"
                strokeWidth="0.485"
                d="M32.46-45.834c3.719 5.07 7.099 11.83 6.085 15.548-1.014 3.718-5.746 14.873-1.352 14.873s7.098-9.802 11.154-6.76 6.084 2.028 12.844 1.352c6.76-.676 7.774 5.408 12.168 4.394 4.394-1.014 17.577-2.366 17.577-4.732s-1.014-10.479 3.38-10.14c4.394.337 17.576 1.69 15.886 5.408-1.69 3.718 0 6.76 5.408 5.746 5.408-1.014 16.562-5.408 20.618 0 4.056 5.408 7.436 7.774 9.126 7.098 1.69-.676 7.774-19.943 7.774-19.943L142.65-60.706 35.841-52.594z"
                opacity="1"
                stopColor="#000"
                stopOpacity="1"
                transform="translate(-.692 -.476)"
                vectorEffect="none"
              ></path>
            </g>
            <g
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
            >
              <path
                fill="#6a6a6a"
                d="M124.859-11.241l-7.999.262-3.864-2.572-3.149 3.083-7.494 2.085-4.91-1.545-3.865 2.529-10.024-2.002-3.859 2.067-7.929 2.12-1.315-2.412-4.325-.532-5.38 3.54-4.125-.766.76 4.241L60.931.605l-6.242.27 1.684 3.783 4.774 1.905 3.08 3.506 1.763.882 2.573 4.781 3.1 1.571 2.71 5.492 6.203.646 3.25-2.049 3.501 2.263 4.083-.34 4.758 3.027 4.274-.212-.006-.213.628-.558 7.984 1.66-.158-5.297 8.141-3.004 1.503 3.004 9.248-2.45.396-4.98 7.983-4.506 1.265 2.688 4.39-.497 4.859.418-.553-1.502.274-12.999-6.013-1.146-5.371-7.76-9.378 1.312z"
                paintOrder="normal"
                transform="translate(-.692 -.476)"
              ></path>
              <path
                fill="gray"
                d="M113.026-13.378l-3.217 2.968-7.424 2.97-4.948-2.227-3.959 1.979-9.65-2.226-3.463 2.968-9.898 1.485v-2.227l-4.206-1.237-5.443 4.701-4.7-1.485-1.981 4.207L55.514.839l-.824.036 2.332 3.69 5.05 1.166 2.526 3.691 2.04-.874 3.497 7.091h2.914l2.526 6.509H81.5l2.235-2.235 3.593 2.818 6.024-1.263 2.817 3.303 4.274 1.36-.03-1.052 7.417.636.741-4.206 8.908-3.464.99 2.226 8.907-2.722.247-3.464 9.155-4.947 1.237 2.721h11.382V-.265l-8.908-3.217-6.185-7.175-9.897.743.494-1.238-8.907.742z"
                transform="translate(-.692 -.476)"
              ></path>
              <path fill="gray" d="M64.1 8.266l1.069-3.594.874 3.011z" paintOrder="normal"></path>
              <path
                fill="#999"
                d="M73.037 14.386l4.08-3.788 2.234 6.897-.777-6.023 2.331.874 1.652 3.109-.486-3.4 3.4 1.845-3.983-3.594-4.662-1.165z"
                paintOrder="normal"
              ></path>
              <path fill="#999" d="M75.466 20.7l2.04-3.788-.875-.68z" paintOrder="normal"></path>
              <path
                fill="#999"
                d="M67.403 7.683l3.788-1.068-.874 6.411 1.943-6.508-2.429-3.886-4.565-.583-1.554 3.983 3.011-2.914 3.886 2.623z"
                paintOrder="normal"
              ></path>
              <path
                fill="#999"
                d="M87.51 18.272l.875-4.663 2.429-.097 2.04 5.245-.875-5.828 4.274 3.691-3.497-5.148-.97-2.04-.584 2.623-3.885.388-.389 8.646z"
                paintOrder="normal"
              ></path>
              <path
                fill="#999"
                d="M82.848 4.575l-2.623 3.011 4.177-.971 1.069 3.594-.097-5.245 7.382 1.748-7.09-4.177L85.86.01z"
                paintOrder="normal"
              ></path>
              <g fill="#999" transform="translate(-.692 -.476)">
                <path d="M105.518 20.028l-.257-9.161 1.028 1.712 2.311-2.74.857 1.884 3.082-3.082 6.421 8.22-6.336-5.908-2.054 2.91-3.767-.342z"></path>
                <path d="M63.956-.964l4.108-3.538 2.511 1.484L77.08-5.3l5.934 1.711-5.592-.684-7.075 2.738-2.054-1.483z"></path>
                <path d="M91.002-1.42l7.075-2.511 2.853 1.255 4.793-2.396 6.048.456 2.74-1.94 8.9 4.223-8.33-2.625-4.109 1.826-5.363-1.255-4.793 2.852-3.423-1.369z"></path>
                <path d="M132.198-.508l2.625-2.967 8.102 4.451-7.646-2.853-4.108 3.195-2.853-2.738z"></path>
                <path d="M123.297 4.514l2.967 8.787-.913-7.646 10.955 2.282-10.498-4.45z"></path>
                <path d="M115.219.134l6.415 4.685-6.314-2.953-2.545 5.091z"></path>
                <path d="M99.23 3.8l-3.462 5.5 3.463-3.768 2.647 2.647z"></path>
              </g>
            </g>
            <g
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
              paintOrder="normal"
            >
              <path
                fill="#6a6a6a"
                d="M34.951 54.785l-.768 5.341 4.268.114 8.715.612 7.492-4.768 2.908-1.229 4.233-3.187 3.336-.227 1.309-7.463-3.315-7.213-6.288.726-6.894-.242-2.298 5.563-2.902 5.2-7.377 4.959.363 1.935z"
                transform="translate(-.692 -.476)"
              ></path>
              <path
                fill="gray"
                d="M34.951 54.785l-.121 4.233 3.991.483 8.345.242 9.433-6.893.967 1.451 4.233-4.112 2.782.605.846-7.74-2.298-6.289-6.288.726-6.894-.242-2.298 5.563-2.902 5.2-7.377 4.959.363 1.935z"
                transform="translate(-.692 -.476)"
              ></path>
              <path
                fill="#999"
                d="M55.907 50.56l-.726-5.443L57.6 41.61l6.289.847-5.805.483 2.54 5.2-3.266-3.023z"
              ></path>
              <path
                fill="#999"
                d="M45.99 57.453L44.9 52.01l-5.08 5.2 5.201-7.377 6.652-3.507-4.233 3.628 6.41.483-7.136.726z"
              ></path>
              <path
                fill="#999"
                d="M48.65 43.061l1.452-4.958 4.716.12 4.475.243-5.684 1.209-.847 4.958-1.21-3.507-1.45 4.233v-2.902z"
              ></path>
            </g>
            <path
              fill="none"
              stroke="#277cb3"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeWidth="0.809"
              d="M55.856 11.933s2.706 7.885 7.348 9.67c4.642 1.786 15.687 7.99 20.711 13.927 3.928 4.642 11.3 5.494 7.499 17.497-4.089 12.914.36 17.24.36 17.24"
              paintOrder="stroke fill markers"
            ></path>
            <path
              fill="none"
              stroke="#277cb3"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeWidth="0.809"
              d="M26.049-46.859s-.606 4.743 3.57 7.655c7.461 5.2-4.395 8.095-2.27 14.707 2.142 6.664 6.73 19.486 18.817 19.981 5.558.228 4.345 8.589 4.964 9.337 2.477 2.99 5.022 6.606 5.29 9.105.535 5-11.427 12.32-14.998 19.283-3.57 6.963 6.603 13.91-12.141 19.461-7.408 2.195-11.447 8.447-15.102 13.819-.913 1.343-1.777 5.357-7.525 7.118-5.748 1.762-19.913 7.394-29.295 3.728-7.564-2.956-2.674 10.926-14.251 16.713-7.913 3.956-9.175 15.958-9.5 27.708"
              paintOrder="stroke fill markers"
            ></path>
            <path
              fill="url(#linearGradient6776)"
              stroke="#277cb3"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeWidth="1.618"
              d="M42.663 90.735c-6.751-.573-7.627 1.126-6.923 7.334.703 6.208-.64 12.836-7.175 16.521-3.268 1.843 3.643 13.137-4.82 16.612-6.898 2.832 1.729 15.057 5.023 17.477 8.681 6.376 35.53-7.714 45.59-3.12 9.343 4.265 23.151-1.232 29.757-6.93 3.172-2.736-.222-4.815 1.161-6.908 2.052-3.105-2.814-4.628-1.249-8.273 1.63-3.794 5.555-9.303 9.998-7.946 4.206 1.285 8.457-3.74 10.261-6.71 2.535-4.17-1.668-7.04-.648-10.818 1.47-5.441-5.417-6.428-7.351-10.157-1.935-3.73-8.08-1.13-9.594-9.03C105.9 74.66 100 75.35 100 75.35s-3.335-7.786-12.025-3.567c-4.721 2.292-9.948 7.408-14.596 3.937-4.4-3.286-5.657 2.69-10.68 1.198-2.818-.837-3.394 3.074-3.995 4.74-.6 1.668-1.22 2.784-4.109 1.435-8.598-4.015-7.38 8.027-11.932 7.64z"
              paintOrder="stroke fill markers"
            ></path>
            <path
              fill="#277cb3"
              stroke="#92a3ac"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeWidth="0"
              d="M89.684 63.335c-.32.796-.833 6.268-2.37 8.257 1.813-.303 3.565-1.628 7.345-1.317-3.37-1.407-4.672-6.293-4.975-6.94z"
              paintOrder="stroke fill markers"
            ></path>
            <path
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.809"
              d="M-10.204 95.498c-5.125 4.057-7.9 6.833-5.979 9.61 1.922 2.775 5.339 3.63 7.901 10.89 2.563 7.26 4.698 8.755 7.688 3.63 2.99-5.125 9.182-7.901 13.239-9.823 4.057-1.922 7.26-4.911 7.901-8.541.64-3.63 4.057-15.375 8.114-16.23 4.058-.853 5.552-7.26 2.35-5.338-3.204 1.922-14.094 10.25-18.579 10.25-4.484 0-11.53-2.776-13.88-.427-2.348 2.349-8.755 5.98-8.755 5.98z"
            ></path>
            <path
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.809"
              d="M15.091 93.936c-.586-.462-2.525 2.244-9.486-.121-5.988-2.035-10.146 4.08-11.683 6.184-3.075 4.209.093 10.146 4.025 10.905 3.884.75 8.192-2.661 11.169-5.627 4.046-4.032 7.734-9.957 5.975-11.341z"
            ></path>
            <path
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.809"
              d="M1.307-4.918c3.86-2.117 6.795-.431 7.791.939.996 1.37 1.739 2.566 4.603 4.931 2.863 2.366 7.52 2.13 6.399 4.745-1.121 2.614.133 5.198 1.877 6.07 1.743.871 5.852 1.992 4.109 3.984-1.744 1.992-7.347.249-8.467 2.988-1.121 2.74-4.234 8.094-7.222 9.339-2.988 1.245-6.6 5.478-11.33 6.599-4.732 1.12-11.705 5.603-11.705 3.237 0-2.365 3.362-6.1 1.245-8.84s-7.368-3.464-6.497-7.449c.872-3.984 9.028-4.91 10.647-8.022 1.618-3.113-.147-7.778-5.28-12.043-2.931-2.434 2.811-4.601 6.173-4.477 3.362.125 7.657-2.001 7.657-2.001z"
            ></path>
            <path
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.809"
              d="M-3.074 2.371C-1.94.972 3.354-.654 5.018.178c1.664.832 8.017 5.748 7.942 6.807 1.284 5.99 4.987 5.576-.454 9.68-2.647 1.967-3.857 8.925-6.05 9.53-2.194.606-9.757 6.732-10.59 4.16-.831-2.571-7.411-4.991-6.882-7.336.53-2.345 9.076-5.143 9.53-8.168.454-3.026-2.723-11.08-1.588-12.48z"
            ></path>
            <path
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.809"
              d="M3.506 5.472c4.31.832 8.017 5.37 6.277 7.715-1.74 2.344-5.9 12.63-9.076 11.193-3.176-1.437 2.42-5.52 2.27-9.227-.152-3.706.529-9.68.529-9.68z"
            ></path>
            <path
              style={{ fontVariationSettings: 'normal' }}
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="4"
              strokeWidth="0.809"
              d="M40.192-45.03s2.785 8.541 1.671 13.369c-1.114 4.827-1.114 7.426 4.085 4.827 5.199-2.6 3.713 3.156 9.84 2.785s11.14-2.785 14.111-.557c2.97 2.228 15.225 2.6 15.41-1.485.186-4.085 2.414-8.912 7.984-8.912s18.381-.743 18.938 3.156c.557 3.9-.557 8.54 6.127 7.427 6.684-1.114 14.668-4.828 17.639-2.785 2.97 2.042 10.397 6.684 10.397 6.684v-6.684"
              opacity="1"
              paintOrder="normal"
              stopColor="#000"
              stopOpacity="1"
              vectorEffect="none"
            ></path>
            <path
              style={{ fontVariationSettings: 'normal' }}
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="4"
              strokeWidth="0.809"
              d="M48.733-46.886s-2.97 5.941 1.114 10.769c4.085 4.827 6.684 5.198 13.554 4.641 6.87-.557 14.11 5.2 15.967 1.3 1.857-3.899 5.756-9.098 13.182-9.283 7.427-.186 25.066-1.671 25.623 2.97.557 4.642 6.87 5.942 14.482 4.642 7.612-1.3 13.182 2.785 13.182 2.785"
              opacity="1"
              paintOrder="normal"
              stopColor="#000"
              stopOpacity="1"
              vectorEffect="none"
            ></path>
            <path
              style={{ fontVariationSettings: 'normal' }}
              fill="none"
              stroke="#20a120"
              strokeDasharray="0.808864, 3.23546"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="4"
              strokeWidth="0.809"
              d="M53.746-47.814c.186 5.198 1.857 11.697 11.326 11.882 9.469.186 16.524-7.426 22.651-8.726 6.127-1.3 28.964-2.414 32.12 0 3.157 2.414 2.414 7.241 7.242 7.241 4.827 0 20.794.186 20.794.186"
              opacity="1"
              paintOrder="normal"
              stopColor="#000"
              stopOpacity="1"
              vectorEffect="none"
            ></path>
          </g>
          <g
            fill="none"
            stroke="#520"
            strokeDasharray="0.808866, 1.61773"
            strokeDashoffset="0"
            strokeLinecap="square"
            strokeOpacity="1"
            strokeWidth="0.809"
            paintOrder="stroke fill markers"
            transform="matrix(1.21109 0 0 1.21206 -19.054 -8.907)"
          >
            <path d="M61.288 56.318c4.638-4.27 19.145.214 19.681-6.494"></path>
            <path d="M36.512 63.393c4.817-.34 13.747 3.673 20.354-4.642"></path>
            <path d="M28.478 59.465c-3.201-3.769-5.368-7.22-5.639-12.377"></path>
            <path d="M55.884 30.174c7.346 2.096 15.88-2.225 21.068 2.856"></path>
            <path d="M29.281 31.96c6.83-4.452 14.326-5.013 22.496-3.036"></path>
            <path d="M14.897 64.083c6.829-4.451 2.47 2.268 12.478.767"></path>
            <path d="M-1.38 55.682C5.644 53.659.694 66.81 10.702 65.309"></path>
            <path d="M-2.175 52.77c4.12-7.76 9.725-19.126 22.258-16.84"></path>
            <path d="M109.587 43.052c-4.242-9.681-15.616-8.798-23.127-8.402"></path>
            <path d="M132.319 48.824c-2.818-7.124-9.936 2.731-16.872 2.558-2.475-.062-3.844-2.726-4.245-4.318"></path>
            <path d="M112.718 70.937c10.67.309 21.652-8.003 20.554-19.063"></path>
          </g>
          <g strokeWidth="1.618" transform="matrix(1.21109 0 0 1.21206 -19.054 -8.907)">
            <g
              fill="#520"
              strokeWidth="0"
              aria-label="Butre"
              paintOrder="stroke fill markers"
              textAnchor="middle"
            >
              <path
                d="M23.248 69.556q.322-.073.622-.477.301-.405.301-1.006 0-.612-.435-.892-.426-.28-1.318-.28-1.327 0-2.178.508-.84.508-.84 1.089 0 .3.228.487.229.177.457.239-.031.405-.28.768-.405-.073-.747-.426-.342-.363-.342-.933 0-1.017 1.13-1.68 1.13-.675 2.925-.675 1.38 0 2.188.436.81.436.81 1.338 0 .705-.488 1.172-.488.467-1.245.705.757.114 1.203.57.83-.217 1.535-.217l.509.031q.02.073.02.145 0 .363-.103.57-.28-.062-.757-.062-.467 0-.84.115.134.383.134.819 0 1.058-.632 1.742-.633.685-1.452.685-.56 0-.934-.342-.373-.332-.373-.965 0-.643.466-1.213.478-.581 1.162-.913-.27-.695-.964-.695h-.073q-.062-.27.031-.612.156 0 .28-.031zm-2.012-1.13q0-.198-.052-.291-.041-.093-.197-.27l.01-.072q.55-.322 1.37-.322.321 0 .477.052.051.104.051.446 0 .332-.539 2.5-.529 2.157-.622 3.215-.612.176-1.359.176-.176 0-.26-.03.053-.654.582-2.718.539-2.064.539-2.687zm2.168 5.02q.28 0 .498-.509.217-.518.217-1.162l-.01-.176q-.384.145-.736.53-.343.383-.343.818 0 .26.114.384.125.114.26.114z"
                style={{}}
              ></path>
              <path
                d="M26.64 73.02q0-.446.27-1.514.269-1.079.269-1.317 0-.25-.373-.654l.01-.135q.705-.145 1.856-.145.052.135.052.488 0 .352-.29 1.504-.29 1.15-.29 1.43t.207.28q.3 0 .695-.217.041-.249.207-.954.374-1.66.374-2.375.363-.145 1.307-.145h.197q0 .487-.29 1.908-.291 1.41-.291 1.66 0 .238.103.238.063 0 .592-.249l.197.332q-.923.767-1.618.767-.332 0-.57-.186-.229-.187-.26-.488-.84.685-1.525.685-.363 0-.601-.239-.228-.238-.228-.674z"
                style={{}}
              ></path>
              <path
                d="M34.978 69.328q-.02.259-.176.601-.394-.041-.747-.041h-.186q-.477 2.313-.477 2.883 0 .3.124.3.135 0 .695-.258l.187.342q-.923.767-1.712.767-.363 0-.601-.228-.228-.228-.228-.601 0-.384.083-.861.093-.477.248-1.162.156-.695.229-1.12-.384.031-.57.062-.022-.124-.022-.332 0-.217.032-.352h.653q.083-.56.083-1.058l-.01-.322v-.03q.809-.28 1.524-.28.042.207.042.518 0 .31-.177 1.172z"
                style={{}}
              ></path>
              <path
                d="M35.103 73.83h-.073q-.02-.084-.02-.374 0-.301.28-1.598.28-1.296.28-1.483 0-.321-.29-.705l-.094-.124.01-.135q.56-.156 1.795-.156.124.27.124.757.114-.186.477-.498.363-.31.747-.31.56 0 .56.974-.052.062-.145.166-.083.094-.353.27-.26.176-.508.218-.01 0-.176-.28-.166-.28-.25-.28-.259.093-.456.31-.477 2.148-.477 2.78 0 .229.01.322-.362.145-1.441.145z"
                style={{}}
              ></path>
              <path
                d="M38.63 72.004q0-1.297.777-2.044.788-.757 1.784-.757.612 0 1.006.301.394.3.394.81 0 .497-.26.84-.248.341-.611.528-.736.363-1.348.456l-.25.032q.073.975.8.975.248 0 .528-.125.28-.124.436-.249l.156-.124.248.332q-.083.114-.331.3-.25.187-.467.312-.602.331-1.317.331-.716 0-1.13-.508-.416-.508-.416-1.41zm1.482-.343q.53-.093.84-.446.312-.352.312-.912t-.332-.56q-.394 0-.612.674-.208.664-.208 1.244z"
                style={{}}
              ></path>
            </g>
            <circle
              cx="30.834"
              cy="63.642"
              r="3.626"
              fill="#ccc"
              fillRule="evenodd"
              stroke="#520"
              strokeDasharray="none"
              strokeOpacity="1"
              strokeWidth="0.485"
              data-testid="btn-butre"
              {...getDotProps('butre')}
            ></circle>
            {location === 'butre' && (
              <path
                fill="#e92828"
                fillOpacity="1"
                stroke="#520"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeOpacity="1"
                strokeWidth="0.485"
                d="M30.666 54.419c-6.987.09.168 9.371.168 9.371s7.268-9.427 0-9.371h-.168z"
                paintOrder="normal"
                data-testid="marker-butre"
              ></path>
            )}
          </g>
          <g strokeWidth="1.618" transform="matrix(1.21109 0 0 1.21206 -19.054 -8.907)">
            <g
              fill="#520"
              strokeWidth="0"
              aria-label="Tabbith"
              paintOrder="stroke fill markers"
              textAnchor="middle"
            >
              <path
                d="M72.528 42.309q-.86 0-1.39.425-.321-.166-.373-.27 0-.622.311-1.1.311-.487.913-.487.622 0 1.97.685.498.26.654.3.404-.32.892-.528.498-.208.86-.208.374 0 .685.218.311.218.311.654 0 .435-.352.767-.343.332-.965.332-.28 0-.622-.083-.332-.093-.405-.104-.321.322-.715.892-.384.56-.654 1.172.166-.02.25-.02.798 0 1.42.466.633.457.633 1.266 0 .809-.602 1.42-.591.612-1.607.612-1.017 0-1.567-.674-.539-.674-.539-1.576-.653.612-.84 1.338-.239-.042-.456-.145-.218-.115-.28-.218.404-1.027 1.721-1.93.25-.808.799-1.597.56-.788 1.193-1.4-.654-.207-1.245-.207zm2.707 4.345q0-.446-.384-.726-.383-.29-.788-.29-.404 0-.684.114-.104.353-.104.85 0 .488.27.851.28.353.705.353.435 0 .705-.353.28-.363.28-.799zm1.473-4.584q0-.197-.3-.197-.291 0-.79.436.188.103.457.103.27 0 .446-.093.187-.093.187-.249z"
                style={{}}
              ></path>
              <path
                d="M80.016 44.196q.021-.02.115-.27.487 0 1.306.291-.259.861-.414 1.805-.156.933-.156 1.338 0 .394.083.394.062 0 .456-.197l.125-.062.186.363q-.093.083-.248.207-.146.124-.56.342-.415.208-.747.208-.643 0-.747-.581-.664.58-1.203.58-.53 0-.903-.414-.363-.415-.363-1.307 0-1.39.57-2.188.571-.81 1.38-.81.581 0 1.12.301zm-1.182 3.537q.249 0 .56-.197.073-1.462.488-2.894-.312-.134-.53-.134-.373 0-.642.736-.26.726-.26 1.608 0 .881.384.881z"
                style={{}}
              ></path>
              <path
                d="M84.59 41.25q0 .79-.529 3.092.623-.446 1.12-.446.509 0 .861.425.363.415.363 1.276 0 1.441-.57 2.23-.56.788-1.37.788-.3 0-.611-.073-.3-.072-.456-.155l-.156-.073q-.664.228-1.296.228-.063-.052-.063-.498 0-.456.581-2.987.581-2.54.581-3.474 0-.166-.052-.426.81-.28 1.546-.28.051.146.051.374zm-.093 3.527q-.197 0-.591.187-.477 2.012-.519 2.883.311.135.591.135.395 0 .654-.726.27-.726.27-1.597 0-.882-.405-.882z"
                style={{}}
              ></path>
              <path
                d="M89.569 41.25q0 .79-.53 3.092.623-.446 1.121-.446.508 0 .86.425.364.415.364 1.276 0 1.441-.57 2.23-.56.788-1.37.788-.3 0-.612-.073-.3-.072-.456-.155l-.156-.073q-.663.228-1.296.228-.062-.052-.062-.498 0-.456.58-2.987.581-2.54.581-3.474 0-.166-.051-.426.809-.28 1.545-.28.052.146.052.374zm-.094 3.527q-.197 0-.59.187-.478 2.012-.52 2.883.312.135.592.135.394 0 .653-.726.27-.726.27-1.597 0-.882-.405-.882z"
                style={{}}
              ></path>
              <path
                d="M94.008 44.539q0 .435-.28 1.504-.28 1.057-.28 1.39 0 .32.093.32.062 0 .477-.196l.125-.062.176.363q-.093.083-.249.207-.155.124-.58.342-.426.208-.789.208-.363 0-.57-.218-.208-.228-.208-.602 0-.383.26-1.348.259-.975.259-1.265 0-.436-.27-.82l-.093-.124.01-.135q.55-.156 1.784-.156.135.156.135.592zm-1.182-1.722q-.208-.208-.208-.55 0-.342.28-.601.29-.26.664-.26.373 0 .58.208.208.207.208.55 0 .331-.3.59-.291.26-.654.26-.363 0-.57-.197z"
                style={{}}
              ></path>
              <path
                d="M97.928 44.02q-.02.26-.176.602-.394-.042-.747-.042h-.186q-.477 2.313-.477 2.883 0 .301.124.301.135 0 .695-.26l.187.343q-.924.768-1.712.768-.363 0-.601-.228-.229-.229-.229-.602 0-.384.083-.86.094-.478.25-1.163.155-.694.228-1.12-.384.031-.57.063-.022-.125-.022-.332 0-.218.031-.353h.654q.083-.56.083-1.058l-.01-.321v-.032q.808-.28 1.524-.28.042.208.042.519 0 .311-.177 1.172z"
                style={{}}
              ></path>
              <path
                d="M98.955 41.645q0-.342-.062-.488.809-.28 1.525-.28.093.094.093.478 0 .684-.58 3.153.84-.612 1.472-.612.394 0 .591.249.208.248.208.663 0 .405-.239 1.338-.239.923-.239 1.266 0 .342.083.342t.436-.187l.125-.062.186.363q-.093.083-.249.207-.155.125-.57.332-.415.208-.768.208-.342 0-.55-.218-.207-.228-.207-.602 0-.383.228-1.38.239-1.005.239-1.264 0-.27-.218-.27-.27 0-.674.228-.57 2.51-.57 3.257-.436.166-1.484.166-.01-.177-.01-.311 0-.446.612-3.05.622-2.603.622-3.526z"
                style={{}}
              ></path>
            </g>
            <circle
              cx="80.432"
              cy="37.224"
              r="3.626"
              fill="#08a200"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#520"
              strokeDasharray="none"
              strokeOpacity="1"
              strokeWidth="0.485"
              data-testid="btn-tabbith"
              {...getDotProps('tabbith')}
            ></circle>
            {location === 'tabbith' && (
              <path
                fill="#e92828"
                fillOpacity="1"
                stroke="#520"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="0.485"
                d="M80.264 27.957c-6.987.088.167 9.18.167 9.18s7.268-9.235 0-9.18a8.3 8.3 0 00-.167 0z"
                paintOrder="normal"
                data-testid="marker-tabbith"
              ></path>
            )}
          </g>
          <g strokeWidth="1.618" transform="matrix(1.21109 0 0 1.21206 -18.066 -7.432)">
            <g
              fill="#520"
              strokeWidth="0"
              aria-label="Oskah"
              paintOrder="stroke fill markers"
              textAnchor="middle"
            >
              <path
                d="M13.903 41.652q.114.28.155.612-.238.145-.726.3-.487.156-.913.187-.456 1.069-1.265 1.722-.799.643-1.991.643-1.193 0-1.826-.799-.622-.809-.622-2.23 0-1.43.664-2.603.664-1.182 1.898-1.846l.218.363q-1.12 1.4-1.12 4.014 0 1.7.518 2.168.239.228.602.228 1.026 0 1.493-1.763-.809-.146-1.245-.685-.435-.55-.435-1.525 0-.985.56-1.763.56-.788 1.348-.788.799 0 1.245.705.446.695.446 1.795 0 .694-.28 1.69.467-.02 1.078-.321zm-2.635-1.286q0-.747-.083-1.1-.083-.363-.394-.363t-.58.467q-.26.456-.26 1.13 0 .675.3 1.059.301.373.851.425.166-.882.166-1.618z"
                style={{}}
              ></path>
              <path
                d="M16.61 43.633q0 .653-.581 1.068-.57.415-1.307.415t-1.172-.311q-.425-.311-.425-.55 0-.145.363-.467.363-.331.612-.404.518.384.85 1.213.384-.03.384-.363 0-.477-.84-1.348-.84-.881-.84-1.4 0-.519.466-.799.467-.29 1.12-.29.664 0 .996.238.332.229.332.633 0 .394-.633 1.1.073.072.187.197.114.114.3.446.188.331.188.622zm-.996-1.628q.435-.374.435-.737t-.497-.363q-.239 0-.395.104-.155.093-.155.218 0 .228.456.643z"
                style={{}}
              ></path>
              <path
                d="M18.259 38.146q0-.342-.063-.487.81-.28 1.525-.28.093.093.093.477 0 .86-.57 3.298.923-.757 1.825-.757.405 0 .685.249t.28.726q0 .892-1.462 1.296.342 1.079.601 1.463.114.166.166.166.114 0 .477-.177l.114-.052.166.353q-.249.228-.695.467-.435.228-.798.228-.81 0-1.13-1.473-.146-.632-.177-1.182.664-.156.985-.374.332-.228.332-.57 0-.353-.353-.353-.352 0-1.182.706-.519 2.188-.519 2.997-.57.166-1.483.166-.01-.176-.01-.311 0-.446.591-3.05.602-2.603.602-3.526z"
                style={{}}
              ></path>
              <path
                d="M25.446 40.698q.021-.021.114-.27.488 0 1.307.29-.26.861-.415 1.805-.155.934-.155 1.338 0 .394.083.394.062 0 .456-.197l.124-.062.187.363q-.093.083-.249.207-.145.125-.56.343-.415.207-.747.207-.643 0-.746-.58-.664.58-1.203.58-.53 0-.903-.415-.363-.415-.363-1.307 0-1.39.57-2.188.571-.81 1.38-.81.58 0 1.12.302zm-1.182 3.536q.249 0 .56-.197.073-1.462.487-2.893-.31-.135-.529-.135-.373 0-.643.736-.259.726-.259 1.608 0 .881.384.881z"
                style={{}}
              ></path>
              <path
                d="M28.547 38.146q0-.342-.062-.487.81-.28 1.525-.28.093.093.093.477 0 .684-.58 3.153.84-.612 1.472-.612.394 0 .591.249.208.249.208.664 0 .404-.239 1.338-.238.923-.238 1.265 0 .342.083.342t.435-.187l.125-.062.186.363q-.093.083-.249.208-.155.124-.57.332-.415.207-.768.207-.342 0-.55-.218-.207-.228-.207-.601 0-.384.228-1.38.239-1.006.239-1.265 0-.27-.218-.27-.27 0-.674.228-.57 2.51-.57 3.257-.436.166-1.484.166-.01-.176-.01-.311 0-.446.612-3.05.622-2.603.622-3.526z"
                style={{}}
              ></path>
            </g>
            <circle
              cx="23.925"
              cy="35.07"
              r="3.626"
              fill="#08a200"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#520"
              strokeDasharray="none"
              strokeOpacity="1"
              strokeWidth="0.485"
              data-testid="btn-oskah"
              {...getDotProps('oskah')}
            ></circle>
            {location === 'oskah' && (
              <path
                fill="#e92828"
                fillOpacity="1"
                stroke="#520"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeOpacity="1"
                strokeWidth="0.485"
                d="M23.757 25.213c-6.987.09.167 9.371.167 9.371s7.268-9.427 0-9.37c-.056-.001-.112-.002-.167 0z"
                paintOrder="normal"
                data-testid="marker-oskah"
              ></path>
            )}
          </g>
          <g strokeWidth="1.618" transform="matrix(1.21109 0 0 1.21206 -56.738 16.235)">
            <g
              fill="#520"
              strokeWidth="0"
              aria-label="Luci"
              paintOrder="stroke fill markers"
              textAnchor="middle"
            >
              <path
                d="M19.674 45.794q-.155.332-.497.601-.332.28-.716.28-.384 0-.757-.217-.363-.208-.591-.426-.229-.217-.654-.674-.415-.456-.57-.612-.55.591-1.338.591-.519 0-.85-.259-.333-.26-.333-.684 0-.436.353-.737.363-.311.965-.311.342 0 .684.114.104-.508.166-1.442-.633-.114-1.037-.528-.394-.426-.394-1.172 0-.29.093-.737.114-.02.3-.02.188 0 .385.145-.135.249-.135.663 0 .84.82 1.038.04-1.131.124-1.587.083-.467.29-.882.207-.425.685-.757.487-.332 1.234-.332t1.151.446q.415.436.415 1.037 0 .84-.778 1.701-.767.85-1.908 1.037-.322 1.4-.467 1.898.166.125.601.467.446.342.675.508.238.156.632.311.405.166.778.166l.436-.03q.176.248.238.404zm-.902-6.368q0-.312-.166-.509-.155-.197-.425-.197-.695 0-1.017 1.515-.176.83-.248 1.203.767-.135 1.306-.757.55-.633.55-1.255zm-4.315 5.134q.363 0 .643-.395-.3-.186-.601-.186-.446 0-.446.29 0 .145.124.218.125.073.28.073z"
                style={{}}
              ></path>
              <path
                d="M19.415 44.435q0-.446.27-1.514.27-1.079.27-1.317 0-.25-.374-.654l.01-.135q.706-.145 1.857-.145.052.135.052.488 0 .352-.29 1.504-.291 1.15-.291 1.43t.207.28q.301 0 .695-.217.042-.249.208-.954.373-1.66.373-2.375.363-.145 1.307-.145h.197q0 .487-.29 1.908-.29 1.41-.29 1.66 0 .238.103.238.062 0 .591-.249l.197.332q-.923.767-1.618.767-.332 0-.57-.186-.228-.187-.26-.488-.84.685-1.524.685-.363 0-.602-.239-.228-.238-.228-.674z"
                style={{}}
              ></path>
              <path
                d="M26.893 41.137q-.415 0-.664.695-.249.684-.249 1.473 0 1.244.768 1.244.249 0 .529-.114.28-.124.446-.238l.155-.125.239.322q-.083.114-.332.3-.249.187-.467.312-.218.124-.56.228-.342.103-.674.103-.809 0-1.213-.529-.405-.529-.405-1.42 0-1.235.674-2.002.685-.768 1.753-.768.695 0 1.1.322.414.311.414.778 0 .456-.29.788-.26 0-.602-.114-.342-.114-.518-.28l.155-.913q-.093-.062-.259-.062z"
                style={{}}
              ></path>
              <path
                d="M30.772 41.261q0 .436-.28 1.504-.28 1.058-.28 1.39 0 .322.093.322.063 0 .477-.198l.125-.062.176.363q-.093.083-.249.208-.155.124-.58.342-.426.207-.789.207-.363 0-.57-.217-.208-.229-.208-.602 0-.384.26-1.348.259-.975.259-1.266 0-.435-.27-.819l-.093-.124.01-.135q.55-.156 1.784-.156.135.156.135.591zM29.59 39.54q-.208-.208-.208-.55 0-.342.28-.602.29-.259.664-.259.373 0 .58.208.208.207.208.55 0 .331-.3.59-.29.26-.654.26-.363 0-.57-.197z"
                style={{}}
              ></path>
            </g>
            <circle
              cx="23.925"
              cy="35.07"
              r="3.626"
              fill="#08a200"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#520"
              strokeDasharray="none"
              strokeOpacity="1"
              strokeWidth="0.485"
              data-testid="btn-luci"
              {...getDotProps('luci')}
            ></circle>
            {location === 'luci' && (
              <path
                fill="#e92828"
                fillOpacity="1"
                stroke="#520"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeOpacity="1"
                strokeWidth="0.485"
                d="M23.757 25.213c-6.987.09.167 9.371.167 9.371s7.268-9.427 0-9.37c-.056-.001-.112-.002-.167 0z"
                paintOrder="normal"
                data-testid="marker-luci"
              ></path>
            )}
          </g>
          <g strokeWidth="1.618" transform="matrix(1.21109 0 0 1.21206 80.413 34.854)">
            <g
              fill="#520"
              strokeWidth="0"
              aria-label="Clionne"
              paintOrder="stroke fill markers"
              textAnchor="middle"
            >
              <path
                d="M10.19 45.337q-1.027 0-1.66-.767-.633-.778-.633-2.054 0-1.275.436-2.313.446-1.047 1.265-1.7.82-.654 1.815-.654.374 0 .664.114.747-.498 1.546-.498.373 0 .694.114.332.104.488.26-.083.352-.27.643-.487-.363-.985-.363t-.913.197q.488.518.488 1.473 0 .943-.498 1.721-.498.778-1.245.778-.446 0-.726-.373-.27-.374-.27-.934 0-1.348 1.007-2.437-.82.031-1.328 1.079-.508 1.037-.508 2.51 0 2.146 1.244 2.146.85 0 1.815-1.057l.363.3q-.134.239-.446.592-.3.352-.601.59-.3.24-.778.436-.477.197-.965.197zm2.219-5.445q0-.85-.384-1.161-.456.404-.736.985-.27.58-.27 1.203 0 .322.114.529.125.207.311.207.405 0 .685-.57t.28-1.193z"
                style={{}}
              ></path>
              <path
                d="M14.151 45.337q-.352 0-.59-.238-.229-.239-.229-.664 0-.436.508-2.748.519-2.324.519-3.35l-.062-.457q.809-.28 1.514-.28.104.145.104.508 0 .83-.53 3.216-.518 2.375-.518 2.769 0 .384.093.384l.726-.26.187.363q-.352.28-.86.519-.499.238-.862.238z"
                style={{}}
              ></path>
              <path
                d="M18.259 41.261q0 .436-.28 1.504-.28 1.058-.28 1.39 0 .322.093.322.062 0 .477-.198l.124-.062.177.363q-.094.083-.25.208-.155.124-.58.342-.425.207-.788.207-.363 0-.57-.217-.208-.229-.208-.602 0-.384.26-1.348.259-.975.259-1.266 0-.435-.27-.819l-.094-.124.01-.135q.55-.156 1.785-.156.135.156.135.591zm-1.183-1.721q-.207-.208-.207-.55 0-.342.28-.602.29-.259.664-.259.373 0 .58.208.208.207.208.55 0 .331-.3.59-.291.26-.654.26-.363 0-.57-.197z"
                style={{}}
              ></path>
              <path
                d="M20.385 45.337q-.716 0-1.12-.497-.405-.509-.405-1.411 0-1.151.612-1.981.622-.83 1.711-.83l.374.021q.176-.093.394-.093.82 0 .82 1.545.425-.02.86-.114l.166-.042.062.405q-.342.145-.923.26l-.207.04q-.197 1.09-.799 1.899-.601.798-1.545.798zm.56-.892q.436 0 .778-.56.352-.56.487-1.182-1.11 0-1.11-.83 0-.332.135-.622l.052-.104q-.041-.02-.104-.02-.062 0-.093.02-.26.166-.487.778-.229.612-.229 1.4 0 1.12.57 1.12z"
                style={{}}
              ></path>
              <path
                d="M23.3 45.244h-.198q0-.487.29-1.867.301-1.38.301-1.566 0-.342-.29-.726l-.093-.124.01-.135q.56-.156 1.794-.156.135.176.145.643.903-.695 1.525-.695.374 0 .591.26.228.259.228.653 0 .384-.238 1.338-.239.954-.239 1.286 0 .322.083.322.063 0 .436-.198l.124-.062.187.363q-.093.083-.249.208-.155.124-.58.342-.415.207-.747.207-.758 0-.758-.86 0-.363.229-1.338.238-.975.238-1.255 0-.28-.176-.28-.3 0-.736.259-.032.176-.208.933-.363 1.629-.363 2.303-.363.145-1.307.145z"
                style={{}}
              ></path>
              <path
                d="M28.568 45.244h-.197q0-.487.29-1.867.301-1.38.301-1.566 0-.342-.29-.726l-.094-.124.01-.135q.56-.156 1.795-.156.135.176.145.643.903-.695 1.525-.695.373 0 .591.26.228.259.228.653 0 .384-.238 1.338-.239.954-.239 1.286 0 .322.083.322.062 0 .436-.198l.124-.062.187.363q-.093.083-.249.208-.156.124-.58.342-.416.207-.748.207-.757 0-.757-.86 0-.363.228-1.338.239-.975.239-1.255 0-.28-.176-.28-.301 0-.737.259-.03.176-.207.933-.363 1.629-.363 2.303-.363.145-1.307.145z"
                style={{}}
              ></path>
              <path
                d="M33.515 43.419q0-1.297.778-2.044.788-.757 1.784-.757.612 0 1.006.301.394.3.394.81 0 .497-.259.84-.249.341-.612.528-.736.363-1.348.456l-.25.032q.073.975.8.975.248 0 .528-.125.28-.124.436-.249l.156-.124.249.332q-.083.114-.332.3-.25.187-.467.312-.602.331-1.317.331-.716 0-1.13-.508-.416-.508-.416-1.41zM35 43.076q.528-.093.84-.446.31-.352.31-.912t-.331-.56q-.394 0-.612.674-.207.664-.207 1.244z"
                style={{}}
              ></path>
            </g>
            <circle
              cx="23.925"
              cy="35.07"
              r="3.626"
              fill="#08a200"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#520"
              strokeDasharray="none"
              strokeOpacity="1"
              strokeWidth="0.485"
              data-testid="btn-clionne"
              {...getDotProps('clionne')}
            ></circle>
            {location === 'clionne' && (
              <path
                fill="#e92828"
                fillOpacity="1"
                stroke="#520"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeOpacity="1"
                strokeWidth="0.485"
                d="M23.757 25.213c-6.987.09.167 9.371.167 9.371s7.268-9.427 0-9.37c-.056-.001-.112-.002-.167 0z"
                paintOrder="normal"
                data-testid="marker-clionne"
              ></path>
            )}
          </g>
        </g>
      </svg>
      <MapDangers dangerIcons={dangerIcons} mapVersion={1} />
    </div>
  );
};

export default Map02;
