function IconItemRings() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100" data-testid="rings">
      <defs>
        <linearGradient
          id="linearGradient15919"
          x1="11"
          x2="53"
          y1="69.927"
          y2="69.927"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient15917"
        ></linearGradient>
        <linearGradient id="linearGradient15917">
          <stop offset="0" stopColor="#959595" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#d6d6d6" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#848484" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient16172"
          x1="11"
          x2="53"
          y1="69.927"
          y2="69.927"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient16244"
        ></linearGradient>
        <linearGradient id="linearGradient16244">
          <stop offset="0" stopColor="#4387bf" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#d6d6d6" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#4387bf" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient16180"
          x1="11"
          x2="53"
          y1="69.927"
          y2="69.927"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient16416"
        ></linearGradient>
        <linearGradient id="linearGradient16416">
          <stop offset="0" stopColor="#952b5b" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#ffadf4" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#952b5b" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient16180-8"
          x1="11"
          x2="53"
          y1="69.927"
          y2="69.927"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient16236"
        ></linearGradient>
        <linearGradient id="linearGradient16236">
          <stop offset="0" stopColor="#957d4c" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#ffd683" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#957d4c" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g>
        <g
          fillOpacity="1"
          fillRule="evenodd"
          strokeWidth="0.159"
          display="inline"
          transform="translate(0 -3.606)"
        >
          <g transform="translate(-15.356 -28.232) scale(1.66666)">
            <path
              fill="#767676"
              d="M11 71.04V64.803h.001c.048-2.536 9.431-4.59 21-4.59 11.568 0 20.95 2.054 20.998 4.59H53v6.236a1.05 1.05 0 00-.061-.354 1.27 1.27 0 00-.107-.233 1.642 1.642 0 00-.16-.229 2.238 2.238 0 00-.21-.225 3.09 3.09 0 00-.258-.22 4.236 4.236 0 00-.307-.217 5.715 5.715 0 00-.353-.212 7.547 7.547 0 00-.615-.308 11.608 11.608 0 00-.97-.392 17.798 17.798 0 00-1.13-.368 25.36 25.36 0 00-1.284-.341 34.107 34.107 0 00-1.425-.313 43.907 43.907 0 00-1.555-.282 53.893 53.893 0 00-1.676-.248 63.779 63.779 0 00-2.715-.305 77.314 77.314 0 00-2.926-.218 88.2 88.2 0 00-3.1-.12 94.988 94.988 0 00-8.393.183 81.195 81.195 0 00-2.86.247 68.34 68.34 0 00-2.636.333 53.793 53.793 0 00-1.617.265 43.832 43.832 0 00-1.491.298 34.202 34.202 0 00-1.355.327 25.332 25.332 0 00-1.21.355 17.777 17.777 0 00-1.051.38 11.616 11.616 0 00-.68.3 8.554 8.554 0 00-.58.312 5.703 5.703 0 00-.478.324 3.606 3.606 0 00-.26.22 2.627 2.627 0 00-.209.226 1.894 1.894 0 00-.159.229 1.425 1.425 0 00-.108.232 1.157 1.157 0 00-.054.236c-.004.04-.007.079-.007.119z"
            ></path>
            <path
              fill="url(#linearGradient15919)"
              d="M11 64.514v6.236c.05 2.537 9.432 4.59 21 4.59 11.569 0 20.952-2.053 20.999-4.59v-6.236a1.05 1.05 0 01-.06.355 1.27 1.27 0 01-.108.232 1.642 1.642 0 01-.159.23 2.238 2.238 0 01-.21.224 3.09 3.09 0 01-.259.221 4.236 4.236 0 01-.306.217 5.715 5.715 0 01-.353.212 7.547 7.547 0 01-.615.308 11.608 11.608 0 01-.97.392 17.798 17.798 0 01-1.131.367 25.36 25.36 0 01-1.284.342 34.107 34.107 0 01-1.424.313 43.907 43.907 0 01-1.555.281 53.893 53.893 0 01-1.676.249 63.779 63.779 0 01-2.715.305 77.314 77.314 0 01-2.926.217 88.2 88.2 0 01-3.1.121 94.988 94.988 0 01-8.393-.183 81.195 81.195 0 01-2.86-.247 68.34 68.34 0 01-2.636-.333 53.793 53.793 0 01-1.617-.266 43.832 43.832 0 01-1.491-.297 34.202 34.202 0 01-1.356-.327 25.332 25.332 0 01-1.208-.355 17.777 17.777 0 01-1.052-.38 11.616 11.616 0 01-.68-.3 8.554 8.554 0 01-.58-.313 5.703 5.703 0 01-.478-.323 3.606 3.606 0 01-.26-.22 2.627 2.627 0 01-.209-.226 1.894 1.894 0 01-.16-.23 1.425 1.425 0 01-.107-.231 1.157 1.157 0 01-.054-.236 1.027 1.027 0 01-.007-.12z"
            ></path>
          </g>
          <g transform="rotate(22.852 152.856 115.831) scale(1.66666)">
            <path
              fill="#265176"
              d="M11 71.04V64.803h.001c.048-2.536 9.431-4.59 21-4.59 11.568 0 20.95 2.054 20.998 4.59H53v6.236a1.05 1.05 0 00-.061-.354 1.27 1.27 0 00-.107-.233 1.642 1.642 0 00-.16-.229 2.238 2.238 0 00-.21-.225 3.09 3.09 0 00-.258-.22 4.236 4.236 0 00-.307-.217 5.715 5.715 0 00-.353-.212 7.547 7.547 0 00-.615-.308 11.608 11.608 0 00-.97-.392 17.798 17.798 0 00-1.13-.368 25.36 25.36 0 00-1.284-.341 34.107 34.107 0 00-1.425-.313 43.907 43.907 0 00-1.555-.282 53.893 53.893 0 00-1.676-.248 63.779 63.779 0 00-2.715-.305 77.314 77.314 0 00-2.926-.218 88.2 88.2 0 00-3.1-.12 94.988 94.988 0 00-8.393.183 81.195 81.195 0 00-2.86.247 68.34 68.34 0 00-2.636.333 53.793 53.793 0 00-1.617.265 43.832 43.832 0 00-1.491.298 34.202 34.202 0 00-1.355.327 25.332 25.332 0 00-1.21.355 17.777 17.777 0 00-1.051.38 11.616 11.616 0 00-.68.3 8.554 8.554 0 00-.58.312 5.703 5.703 0 00-.478.324 3.606 3.606 0 00-.26.22 2.627 2.627 0 00-.209.226 1.894 1.894 0 00-.159.229 1.425 1.425 0 00-.108.232 1.157 1.157 0 00-.054.236c-.004.04-.007.079-.007.119z"
            ></path>
            <path
              fill="url(#linearGradient16172)"
              d="M11 64.514v6.236c.05 2.537 9.432 4.59 21 4.59 11.569 0 20.952-2.053 20.999-4.59v-6.236a1.05 1.05 0 01-.06.355 1.27 1.27 0 01-.108.232 1.642 1.642 0 01-.159.23 2.238 2.238 0 01-.21.224 3.09 3.09 0 01-.259.221 4.236 4.236 0 01-.306.217 5.715 5.715 0 01-.353.212 7.547 7.547 0 01-.615.308 11.608 11.608 0 01-.97.392 17.798 17.798 0 01-1.131.367 25.36 25.36 0 01-1.284.342 34.107 34.107 0 01-1.424.313 43.907 43.907 0 01-1.555.281 53.893 53.893 0 01-1.676.249 63.779 63.779 0 01-2.715.305 77.314 77.314 0 01-2.926.217 88.2 88.2 0 01-3.1.121 94.988 94.988 0 01-8.393-.183 81.195 81.195 0 01-2.86-.247 68.34 68.34 0 01-2.636-.333 53.793 53.793 0 01-1.617-.266 43.832 43.832 0 01-1.491-.297 34.202 34.202 0 01-1.356-.327 25.332 25.332 0 01-1.208-.355 17.777 17.777 0 01-1.052-.38 11.616 11.616 0 01-.68-.3 8.554 8.554 0 01-.58-.313 5.703 5.703 0 01-.478-.323 3.606 3.606 0 01-.26-.22 2.627 2.627 0 01-.209-.226 1.894 1.894 0 01-.16-.23 1.425 1.425 0 01-.107-.231 1.157 1.157 0 01-.054-.236 1.027 1.027 0 01-.007-.12z"
            ></path>
          </g>
          <g transform="rotate(-19.843 -137.884 93.005) scale(1.66666)">
            <path
              fill="#a81d86"
              d="M11 71.04V64.803h.001c.048-2.536 9.431-4.59 21-4.59 11.568 0 20.95 2.054 20.998 4.59H53v6.236a1.05 1.05 0 00-.061-.354 1.27 1.27 0 00-.107-.233 1.642 1.642 0 00-.16-.229 2.238 2.238 0 00-.21-.225 3.09 3.09 0 00-.258-.22 4.236 4.236 0 00-.307-.217 5.715 5.715 0 00-.353-.212 7.547 7.547 0 00-.615-.308 11.608 11.608 0 00-.97-.392 17.798 17.798 0 00-1.13-.368 25.36 25.36 0 00-1.284-.341 34.107 34.107 0 00-1.425-.313 43.907 43.907 0 00-1.555-.282 53.893 53.893 0 00-1.676-.248 63.779 63.779 0 00-2.715-.305 77.314 77.314 0 00-2.926-.218 88.2 88.2 0 00-3.1-.12 94.988 94.988 0 00-8.393.183 81.195 81.195 0 00-2.86.247 68.34 68.34 0 00-2.636.333 53.793 53.793 0 00-1.617.265 43.832 43.832 0 00-1.491.298 34.202 34.202 0 00-1.355.327 25.332 25.332 0 00-1.21.355 17.777 17.777 0 00-1.051.38 11.616 11.616 0 00-.68.3 8.554 8.554 0 00-.58.312 5.703 5.703 0 00-.478.324 3.606 3.606 0 00-.26.22 2.627 2.627 0 00-.209.226 1.894 1.894 0 00-.159.229 1.425 1.425 0 00-.108.232 1.157 1.157 0 00-.054.236c-.004.04-.007.079-.007.119z"
            ></path>
            <path
              fill="url(#linearGradient16180)"
              d="M11 64.514v6.236c.05 2.537 9.432 4.59 21 4.59 11.569 0 20.952-2.053 20.999-4.59v-6.236a1.05 1.05 0 01-.06.355 1.27 1.27 0 01-.108.232 1.642 1.642 0 01-.159.23 2.238 2.238 0 01-.21.224 3.09 3.09 0 01-.259.221 4.236 4.236 0 01-.306.217 5.715 5.715 0 01-.353.212 7.547 7.547 0 01-.615.308 11.608 11.608 0 01-.97.392 17.798 17.798 0 01-1.131.367 25.36 25.36 0 01-1.284.342 34.107 34.107 0 01-1.424.313 43.907 43.907 0 01-1.555.281 53.893 53.893 0 01-1.676.249 63.779 63.779 0 01-2.715.305 77.314 77.314 0 01-2.926.217 88.2 88.2 0 01-3.1.121 94.988 94.988 0 01-8.393-.183 81.195 81.195 0 01-2.86-.247 68.34 68.34 0 01-2.636-.333 53.793 53.793 0 01-1.617-.266 43.832 43.832 0 01-1.491-.297 34.202 34.202 0 01-1.356-.327 25.332 25.332 0 01-1.208-.355 17.777 17.777 0 01-1.052-.38 11.616 11.616 0 01-.68-.3 8.554 8.554 0 01-.58-.313 5.703 5.703 0 01-.478-.323 3.606 3.606 0 01-.26-.22 2.627 2.627 0 01-.209-.226 1.894 1.894 0 01-.16-.23 1.425 1.425 0 01-.107-.231 1.157 1.157 0 01-.054-.236 1.027 1.027 0 01-.007-.12z"
            ></path>
          </g>
          <g transform="rotate(17.887 331.317 87.531) scale(1.66666)">
            <path
              fill="#6d5015"
              d="M11 71.04V64.803h.001c.048-2.536 9.431-4.59 21-4.59 11.568 0 20.95 2.054 20.998 4.59H53v6.236a1.05 1.05 0 00-.061-.354 1.27 1.27 0 00-.107-.233 1.642 1.642 0 00-.16-.229 2.238 2.238 0 00-.21-.225 3.09 3.09 0 00-.258-.22 4.236 4.236 0 00-.307-.217 5.715 5.715 0 00-.353-.212 7.547 7.547 0 00-.615-.308 11.608 11.608 0 00-.97-.392 17.798 17.798 0 00-1.13-.368 25.36 25.36 0 00-1.284-.341 34.107 34.107 0 00-1.425-.313 43.907 43.907 0 00-1.555-.282 53.893 53.893 0 00-1.676-.248 63.779 63.779 0 00-2.715-.305 77.314 77.314 0 00-2.926-.218 88.2 88.2 0 00-3.1-.12 94.988 94.988 0 00-8.393.183 81.195 81.195 0 00-2.86.247 68.34 68.34 0 00-2.636.333 53.793 53.793 0 00-1.617.265 43.832 43.832 0 00-1.491.298 34.202 34.202 0 00-1.355.327 25.332 25.332 0 00-1.21.355 17.777 17.777 0 00-1.051.38 11.616 11.616 0 00-.68.3 8.554 8.554 0 00-.58.312 5.703 5.703 0 00-.478.324 3.606 3.606 0 00-.26.22 2.627 2.627 0 00-.209.226 1.894 1.894 0 00-.159.229 1.425 1.425 0 00-.108.232 1.157 1.157 0 00-.054.236c-.004.04-.007.079-.007.119z"
            ></path>
            <path
              fill="url(#linearGradient16180-8)"
              d="M11 64.514v6.236c.05 2.537 9.432 4.59 21 4.59 11.569 0 20.952-2.053 20.999-4.59v-6.236a1.05 1.05 0 01-.06.355 1.27 1.27 0 01-.108.232 1.642 1.642 0 01-.159.23 2.238 2.238 0 01-.21.224 3.09 3.09 0 01-.259.221 4.236 4.236 0 01-.306.217 5.715 5.715 0 01-.353.212 7.547 7.547 0 01-.615.308 11.608 11.608 0 01-.97.392 17.798 17.798 0 01-1.131.367 25.36 25.36 0 01-1.284.342 34.107 34.107 0 01-1.424.313 43.907 43.907 0 01-1.555.281 53.893 53.893 0 01-1.676.249 63.779 63.779 0 01-2.715.305 77.314 77.314 0 01-2.926.217 88.2 88.2 0 01-3.1.121 94.988 94.988 0 01-8.393-.183 81.195 81.195 0 01-2.86-.247 68.34 68.34 0 01-2.636-.333 53.793 53.793 0 01-1.617-.266 43.832 43.832 0 01-1.491-.297 34.202 34.202 0 01-1.356-.327 25.332 25.332 0 01-1.208-.355 17.777 17.777 0 01-1.052-.38 11.616 11.616 0 01-.68-.3 8.554 8.554 0 01-.58-.313 5.703 5.703 0 01-.478-.323 3.606 3.606 0 01-.26-.22 2.627 2.627 0 01-.209-.226 1.894 1.894 0 01-.16-.23 1.425 1.425 0 01-.107-.231 1.157 1.157 0 01-.054-.236 1.027 1.027 0 01-.007-.12z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconItemRings;
