function IconUpgradeCounterDangerTricksters() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100"
      data-testid="counterDanger__tricksters"
    >
      <g>
        <g
          strokeLinecap="round"
          display="inline"
          paintOrder="stroke fill markers"
          transform="translate(0 -.281)"
        >
          <path fill="#575795" strokeWidth="0" d="M2 17.467h48v71.465H2z"></path>
          <path fill="#7676b1" fillOpacity="1" strokeWidth="0" d="M50 17.467h48v71.465H50z"></path>
          <path fill="#ccc" fillOpacity="1" strokeWidth="0" d="M4 23.964h46V84.87H4z"></path>
          <path fill="#d6d6d6" fillOpacity="1" strokeWidth="0" d="M50 23.964h46V84.87H50z"></path>
          <path
            fill="#999"
            fillOpacity="1"
            strokeWidth="0"
            d="M24.145 11.284c-5.329-.074-11.326 1.262-17.846 4.953-.157 20.372-.369 45.798 0 61.159C38.63 65.87 49.036 81.643 50 83.256v.241s.043-.071.07-.12l.025.04v-.08c.965-1.616 11.373-17.386 43.7-5.86.37-15.361.158-40.788 0-61.16C68.348 1.91 50.913 23.337 50.08 24.39c-.12-.155-10.047-12.885-25.935-13.105z"
          ></path>
          <path
            fill="#e8e8e8"
            fillOpacity="1"
            strokeWidth="0"
            d="M6.3 17.956c26.08-14.766 43.795 8.17 43.795 8.17v59.01s-9.76-18.155-43.796-6.021c-.368-15.36-.157-40.787 0-61.159z"
          ></path>
          <path
            fill="#f7f7f7"
            fillOpacity="1"
            strokeWidth="0"
            d="M93.795 18.036C67.715 3.271 50 26.206 50 26.206v59.01s9.759-18.155 43.795-6.02c.37-15.361.158-40.787 0-61.16z"
            display="inline"
          ></path>
          <g
            fill="#c6c6c6"
            strokeWidth="1.11"
            color="#000"
            transform="matrix(1 0 0 .8121 0 11.281)"
          >
            <path d="M74.69 51.037c-2.789.014-5.717.558-8.569 1.984-5.063 2.532-8.26 5.45-10.187 7.768-1.928 2.317-2.639 4.16-2.639 4.16a1.5 1.5 0 00.879 1.93 1.5 1.5 0 001.93-.88s.457-1.273 2.136-3.292c1.68-2.019 4.529-4.656 9.221-7.002 9.507-4.754 21.063 2.531 21.063 2.531a1.5 1.5 0 002.074-.441 1.5 1.5 0 00-.442-2.076s-7.102-4.725-15.466-4.682z"></path>
            <path d="M74.69 35.162c-2.789.014-5.717.558-8.569 1.984-5.063 2.532-8.26 5.45-10.187 7.768-1.928 2.317-2.639 4.16-2.639 4.16a1.5 1.5 0 00.879 1.93 1.5 1.5 0 001.93-.88s.457-1.273 2.136-3.292c1.68-2.019 4.529-4.656 9.221-7.002 9.507-4.754 21.063 2.531 21.063 2.531a1.5 1.5 0 002.074-.441 1.5 1.5 0 00-.442-2.076s-7.102-4.725-15.466-4.682z"></path>
            <path d="M74.69 19.287c-2.789.014-5.717.558-8.569 1.984-5.063 2.532-8.26 5.45-10.187 7.768-1.928 2.317-2.639 4.16-2.639 4.16a1.5 1.5 0 00.879 1.93 1.5 1.5 0 001.93-.88s.457-1.273 2.136-3.292c1.68-2.019 4.529-4.656 9.221-7.002 9.507-4.754 21.063 2.531 21.063 2.531a1.5 1.5 0 002.074-.441 1.5 1.5 0 00-.442-2.076s-7.102-4.725-15.466-4.682z"></path>
          </g>
          <g
            fill="#c6c6c6"
            strokeWidth="1.11"
            color="#000"
            transform="matrix(1 0 0 .8121 0 11.281)"
          >
            <path d="M74.69 51.037c-2.789.014-5.717.558-8.569 1.984-5.063 2.532-8.26 5.45-10.187 7.768-1.928 2.317-2.639 4.16-2.639 4.16A1.5 1.5 0 0056.104 66s.457-1.273 2.136-3.292c1.68-2.019 4.529-4.656 9.221-7.002 9.507-4.754 21.063 2.531 21.063 2.531a1.5 1.5 0 101.632-2.517s-7.102-4.725-15.466-4.682zm-49.38 0c2.789.014 5.717.558 8.569 1.984 5.063 2.532 8.26 5.45 10.187 7.768 1.928 2.317 2.639 4.16 2.639 4.16A1.5 1.5 0 0143.896 66s-.457-1.273-2.136-3.292c-1.68-2.019-4.529-4.656-9.221-7.002-9.507-4.754-21.063 2.531-21.063 2.531a1.5 1.5 0 11-1.632-2.517s7.102-4.725 15.466-4.682z"></path>
            <path d="M74.69 35.162c-2.789.014-5.717.558-8.569 1.984-5.063 2.532-8.26 5.45-10.187 7.768-1.928 2.317-2.639 4.16-2.639 4.16a1.5 1.5 0 002.809 1.05s.457-1.273 2.136-3.292c1.68-2.019 4.529-4.656 9.221-7.002 9.507-4.754 21.063 2.531 21.063 2.531a1.5 1.5 0 101.632-2.517s-7.102-4.725-15.466-4.682zm-49.38 0c2.789.014 5.717.558 8.569 1.984 5.063 2.532 8.26 5.45 10.187 7.768 1.928 2.317 2.639 4.16 2.639 4.16a1.5 1.5 0 01-2.809 1.05s-.457-1.273-2.136-3.292c-1.68-2.019-4.529-4.656-9.221-7.002-9.507-4.754-21.063 2.531-21.063 2.531a1.5 1.5 0 11-1.632-2.517s7.102-4.725 15.466-4.682z"></path>
            <path d="M74.69 19.287c-2.789.014-5.717.558-8.569 1.984-5.063 2.532-8.26 5.45-10.187 7.768-1.928 2.317-2.639 4.16-2.639 4.16a1.5 1.5 0 002.809 1.05s.457-1.273 2.136-3.292c1.68-2.019 4.529-4.656 9.221-7.002 9.507-4.754 21.063 2.531 21.063 2.531a1.5 1.5 0 101.632-2.517s-7.102-4.725-15.466-4.682zm-49.38 0c2.789.014 5.717.558 8.569 1.984 5.063 2.532 8.26 5.45 10.187 7.768 1.928 2.317 2.639 4.16 2.639 4.16a1.5 1.5 0 01-2.809 1.05s-.457-1.273-2.136-3.292c-1.68-2.019-4.529-4.656-9.221-7.002-9.507-4.754-21.063 2.531-21.063 2.531a1.5 1.5 0 11-1.632-2.517s7.102-4.725 15.466-4.682z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconUpgradeCounterDangerTricksters;
