function ImgCloud1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 100" xmlSpace="preserve">
      <defs>
        <clipPath id="clipPath23807" clipPathUnits="userSpaceOnUse">
          <path
            fill="#fff"
            strokeWidth="0"
            d="M-69.73 153.645s16.351-.962 20.198-7.214c3.847-6.251 11.06-9.377 3.366-15.148-7.694-5.77-22.842 2.405-22.842 2.405s-5.05-18.274-16.11-15.389c-11.06 2.886-24.044 18.274-24.044 18.274s-14.187-8.175-21.64-5.05c-7.454 3.127-7.695 12.023-7.695 12.023s-8.415-4.568-15.87-.24c-7.453 4.328-9.617 8.415-9.617 8.415s-49.532-2.404-51.215 5.29c-1.683 7.694 32.22 6.011 32.22 6.011s54.581-4.809 68.046-2.645c13.465 2.164 35.345 5.05 41.357 1.203 6.01-3.848 3.847-7.935 3.847-7.935z"
            display="inline"
            opacity="1"
            paintOrder="stroke fill markers"
          ></path>
        </clipPath>
        <clipPath id="clipPath24744" clipPathUnits="userSpaceOnUse">
          <path
            fill="#fff"
            strokeWidth="0"
            d="M-117.818 138.256s-29.094-2.645-40.636-1.202c-11.541 1.443-33.181 6.733-40.154 6.733-6.973 0-12.503-.962-12.503-4.088 0-3.126 13.465-3.607 13.465-3.607s.24-8.175 9.137-10.339c8.896-2.164 13.224 1.443 13.224 1.443s-3.847-19.717 14.186-21.16c18.034-1.442 27.652 18.755 27.652 18.755s4.087-3.366 8.896 2.645c4.809 6.011 6.733 10.82 6.733 10.82z"
            display="inline"
            opacity="1"
            paintOrder="stroke fill markers"
          ></path>
        </clipPath>
      </defs>
      <g>
        <g display="inline">
          <g
            strokeWidth="0.738"
            display="inline"
            transform="matrix(1.35417 0 0 1.35417 287.88 -134.738)"
          >
            <path
              fill="#fff"
              strokeWidth="0"
              d="M-117.818 138.256s-29.094-2.645-40.636-1.202c-11.541 1.443-33.181 6.733-40.154 6.733-6.973 0-12.503-.962-12.503-4.088 0-3.126 13.465-3.607 13.465-3.607s.24-8.175 9.137-10.339c8.896-2.164 13.224 1.443 13.224 1.443s-3.847-19.717 14.186-21.16c18.034-1.442 27.652 18.755 27.652 18.755s4.087-3.366 8.896 2.645c4.809 6.011 6.733 10.82 6.733 10.82z"
              display="inline"
              opacity="1"
              paintOrder="stroke fill markers"
            ></path>
            <g strokeWidth="0.738" clipPath="url(#clipPath24744)">
              <path
                fill="#d1d5f8"
                fillOpacity="1"
                strokeWidth="0"
                d="M-182.545 105.122s11.685-6.358 24.838 7.686c6.718 7.173.057 17.03.057 17.03s9.468 4.698 9.468 10.741c0 6.043-56.729 10.495-56.729 10.495l-20.326-5.494-1.099-23.622z"
                opacity="1"
                paintOrder="stroke fill markers"
              ></path>
            </g>
          </g>
          <g
            strokeWidth="0.91"
            display="inline"
            transform="matrix(.9094 0 0 1.3274 235.01 -129.88)"
          >
            <path
              fill="#fff"
              strokeWidth="0"
              d="M-69.73 153.645s18.822 2.75 24.431-1.985c5.341-4.508 6.828-14.606-.867-20.377-7.694-5.77-22.842 2.405-22.842 2.405s-5.05-18.274-16.11-15.389c-11.06 2.886-24.044 18.274-24.044 18.274s-14.187-8.175-21.64-5.05c-7.454 3.127-7.695 12.023-7.695 12.023s-8.415-4.568-15.87-.24c-7.453 4.328-9.617 8.415-9.617 8.415s-49.532-2.404-51.215 5.29c-1.683 7.694 32.22 6.011 32.22 6.011s54.581-4.809 68.046-2.645c13.465 2.164 35.345 5.05 41.357 1.203 6.01-3.848 3.847-7.935 3.847-7.935z"
              display="inline"
              opacity="1"
              paintOrder="stroke fill markers"
            ></path>
            <g strokeWidth="0.91" clipPath="url(#clipPath23807)">
              <path
                fill="#d1d5f8"
                fillOpacity="1"
                strokeWidth="0"
                d="M-105.796 145.23s-8.656-18.034-17.312-17.794c-8.656.24-95.457 17.312-95.457 17.312l3.366 24.285 147.393-3.125s-3.366-4.088-6.251-6.252c-2.886-2.164-7.454-1.924-7.454-1.924s13.946-9.617 7.694-16.83c-6.251-7.214-15.629 0-15.629 0s-4.087-7.214-8.896-4.81c-4.81 2.405-7.454 9.137-7.454 9.137z"
                display="inline"
                opacity="1"
                paintOrder="stroke fill markers"
              ></path>
            </g>
            <path
              fill="#b7bce8"
              fillOpacity="1"
              strokeWidth="0"
              d="M-241.403 165.824s1.462-3.152 3.877-3.66c2.415-.509 4.956 1.016 4.956 1.016s.382-3.05 2.923-4.32c2.542-1.272 5.338-.382 5.338-.382s1.144-8.26 10.421-7.88c9.278.382 11.692 7.88 11.692 7.88s5.465-1.652 10.803.508c5.337 2.16 7.879 6.99 7.879 6.99z"
              display="inline"
              opacity="1"
              paintOrder="stroke fill markers"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ImgCloud1;
