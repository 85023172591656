import React from 'react';

function ImgCloud3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 100" xmlSpace="preserve">
      <defs>
        <clipPath id="clipPath25458" clipPathUnits="userSpaceOnUse">
          <path
            fill="#d1d5f8"
            fillOpacity="1"
            strokeWidth="0"
            d="M111.035-135.997c28.405 0 55.518 12.481 74.454 6.026 18.936-6.456 7.316-21.519-3.443-25.392-10.76-3.874-16.784.86-16.784.86s22.379-42.176-24.531-53.796c-46.91-11.62-52.505 24.962-52.505 24.962s-12.05-10.33-22.38-2.582c-10.328 7.746-6.025 18.505-6.025 18.505s-14.202-.86-20.227 9.469c-6.025 10.328 4.734 14.202-8.177 13.771-12.911-.43-25.392 0-19.797 8.177 5.595 8.177 99.415 0 99.415 0z"
            opacity="1"
            paintOrder="stroke fill markers"
          ></path>
        </clipPath>
      </defs>
      <g>
        <g display="inline" transform="translate(-.247 218.578)">
          <g
            fillOpacity="1"
            strokeWidth="0"
            clipPath="url(#clipPath25458)"
            paintOrder="stroke fill markers"
          >
            <path
              fill="#fff"
              d="M111.035-135.997c28.405 0 55.518 12.481 74.454 6.026 18.936-6.456 7.316-21.519-3.443-25.392-10.76-3.874-16.784.86-16.784.86s22.379-42.176-24.531-53.796c-46.91-11.62-52.505 24.962-52.505 24.962s-12.05-10.33-22.38-2.582c-10.328 7.746-6.025 18.505-6.025 18.505s-14.202-.86-20.227 9.469c-6.025 10.328 4.734 14.202-8.177 13.771-12.911-.43-25.392 0-19.797 8.177 5.595 8.177 99.415 0 99.415 0z"
              display="inline"
              opacity="1"
            ></path>
            <path
              fill="#d1d5f8"
              d="M46.91-197.11s19.367 4.305 27.114 14.203c7.746 9.899 6.455 14.633 6.455 14.633s3.873-6.886 12.48-4.734c8.608 2.151 12.051 8.177 12.051 8.177s5.595-26.253 23.67-18.076c18.076 8.177 9.469 32.278 9.469 32.278s9.898-4.734 15.062 0c5.165 4.734 8.177 15.493 8.177 15.493s5.165-.43 9.899 2.152c4.734 2.582 7.316 8.607 7.316 8.607l-166.553-1.29-12.91-40.025z"
              display="inline"
              opacity="1"
            ></path>
            <path
              fill="#b7bce8"
              d="M11.62-161.819c24.093-13.932 49.67 11.166 49.67 11.166s12.536-10.258 17.347 3.405c3.664 10.405 14.753 20.29 14.753 20.29l-90.165.963z"
              opacity="1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ImgCloud3;
