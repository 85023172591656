const IconInfo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <path
        fill="#fff"
        d="M36.228 79.507q0-1.292.62-4.082.362-1.602 1.086-4.238l6.356-23.77q.103-.414.155-.828.103-.413.103-.775 0-2.119-1.343-2.635-1.344-.517-4.91-.672v-2.79q2.998-.207 8.682-.827 5.736-.672 8.113-1.137l4.548-.879-8.217 30.127q-1.033 3.876-1.395 5.375-.93 3.824-.93 4.96 0 1.138.516 1.5.517.31 1.137.31 1.602 0 3.98-2.74 2.376-2.738 4.185-5.735l2.326 1.498q-5.168 7.597-7.907 10.232-4.496 4.34-9.612 4.34-2.894 0-5.219-1.756-2.274-1.809-2.274-5.478zm20.464-66.249q2.946 0 5.013 2.067 2.067 2.067 2.067 5.013 0 2.945-2.067 5.064-2.067 2.067-5.013 2.067-2.945 0-5.064-2.067-2.067-2.119-2.067-5.064 0-2.946 2.067-5.013 2.119-2.067 5.064-2.067z"
        style={{}}
        fontFamily="Times"
        fontStyle="italic"
        fontWeight="bold"
      ></path>
    </svg>
  );
};

export default IconInfo;
